import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { User } from '../../types/user';

export async function createCoach(coachData: Omit<User, 'id'>): Promise<User> {
  const now = Timestamp.now();
  const docRef = await addDoc(collection(db, 'users'), {
    ...coachData,
    role: 'coach',
    createdAt: now,
    updatedAt: now,
  });

  return {
    id: docRef.id,
    ...coachData,
    createdAt: now.toDate(),
    updatedAt: now.toDate(),
  };
}

export async function getCoachesByTenant(tenantId: string): Promise<User[]> {
  const coachesQuery = query(
    collection(db, 'users'),
    where('tenantId', '==', tenantId),
    where('role', '==', 'coach')
  );

  const snapshot = await getDocs(coachesQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate(),
    updatedAt: doc.data().updatedAt.toDate(),
  } as User));
}

export async function assignCoachToTeam(coachId: string, teamId: string): Promise<void> {
  const now = Timestamp.now();
  const coachRef = doc(db, 'users', coachId);
  await updateDoc(coachRef, {
    teamId,
    updatedAt: now,
  });
}
import { CreditCard, Users, RefreshCw, TrendingUp } from 'lucide-react';
import { formatCurrency } from '../../lib/utils';

export default function SubscriptionStats() {
  const stats = [
    {
      name: 'Monthly Revenue',
      value: formatCurrency(52000),
      change: '+12.5%',
      changeType: 'positive',
      icon: CreditCard,
    },
    {
      name: 'Active Subscriptions',
      value: '156',
      change: '+5.4%',
      changeType: 'positive',
      icon: Users,
    },
    {
      name: 'Renewal Rate',
      value: '94.2%',
      change: '+2.3%',
      changeType: 'positive',
      icon: RefreshCw,
    },
    {
      name: 'Avg. Revenue/Sub',
      value: formatCurrency(333),
      change: '+8.1%',
      changeType: 'positive',
      icon: TrendingUp,
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:py-6"
        >
          <dt>
            <div className="absolute rounded-md bg-primary-50 p-3">
              <stat.icon className="h-6 w-6 text-primary-600" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">{stat.name}</p>
          </dt>
          <dd className="ml-16 flex items-baseline">
            <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
            <p
              className={`ml-2 flex items-baseline text-sm font-semibold ${
                stat.changeType === 'positive' ? 'text-green-600' : 'text-red-600'
              }`}
            >
              {stat.change}
            </p>
          </dd>
        </div>
      ))}
    </div>
  );
}
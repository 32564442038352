import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { User } from '../../types/user';

export async function getAllUsers(): Promise<User[]> {
  try {
    const usersQuery = query(
      collection(db, 'users'),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(usersQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
      updatedAt: doc.data().updatedAt.toDate(),
    } as User));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
}

export async function getUsersByTenant(tenantId: string): Promise<User[]> {
  const usersQuery = query(
    collection(db, 'users'),
    where('tenantId', '==', tenantId),
    orderBy('createdAt', 'desc')
  );
  
  const snapshot = await getDocs(usersQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate(),
    updatedAt: doc.data().updatedAt.toDate(),
  } as User));
}

export async function updateUserRole(
  userId: string,
  role: User['role']
): Promise<void> {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    role,
    updatedAt: new Date(),
  });
}

export async function getUserById(userId: string): Promise<User | null> {
  const userDoc = await getDoc(doc(db, 'users', userId));
  if (!userDoc.exists()) {
    return null;
  }

  return {
    id: userDoc.id,
    ...userDoc.data(),
    createdAt: userDoc.data().createdAt.toDate(),
    updatedAt: userDoc.data().updatedAt.toDate(),
  } as User;
}
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { Subscription } from '../../types/tenant';

export async function getAllSubscriptions(): Promise<Subscription[]> {
  const subsQuery = query(
    collection(db, 'subscriptions'),
    orderBy('createdAt', 'desc')
  );
  
  const snapshot = await getDocs(subsQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    currentPeriodEnd: doc.data().currentPeriodEnd.toDate(),
  } as Subscription));
}

export async function getSubscriptionDetails(id: string): Promise<Subscription | null> {
  const subDoc = await getDoc(doc(db, 'subscriptions', id));
  if (!subDoc.exists()) {
    return null;
  }

  return {
    id: subDoc.id,
    ...subDoc.data(),
    currentPeriodEnd: subDoc.data().currentPeriodEnd.toDate(),
  } as Subscription;
}

export async function updateSubscription(
  id: string,
  data: Partial<Subscription>
): Promise<void> {
  const subRef = doc(db, 'subscriptions', id);
  await updateDoc(subRef, {
    ...data,
    updatedAt: new Date(),
  });
}
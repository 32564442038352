import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './context/AuthContext';
import { TenantProvider } from './context/TenantContext';
import AppRoutes from './routes';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <TenantProvider>
          <AppRoutes />
          <Toaster position="top-right" />
        </TenantProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
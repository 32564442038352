import { useState } from 'react';
import { Tab } from '@headlessui/react';
import SecuritySettings from '../../components/superadmin/SecuritySettings';
import EmailSettings from '../../components/superadmin/EmailSettings';
import BillingSettings from '../../components/superadmin/BillingSettings';
import SystemLogs from '../../components/superadmin/SystemLogs';

export default function SystemSettings() {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = ['Security', 'Email', 'Billing', 'System Logs'];

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">System Settings</h1>
        <p className="mt-1 text-sm text-gray-500">
          Configure global system settings and preferences
        </p>
      </div>

      <div className="bg-white shadow-sm rounded-lg">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="border-b border-gray-200">
            <div className="flex">
              {tabs.map((tab, index) => (
                <Tab
                  key={tab}
                  className={({ selected }) =>
                    `px-4 py-2 text-sm font-medium border-b-2 focus:outline-none ${
                      selected
                        ? 'text-primary-600 border-primary-600'
                        : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
                    }`
                  }
                >
                  {tab}
                </Tab>
              ))}
            </div>
          </Tab.List>

          <div className="p-6">
            <Tab.Panels>
              <Tab.Panel>
                <SecuritySettings />
              </Tab.Panel>
              <Tab.Panel>
                <EmailSettings />
              </Tab.Panel>
              <Tab.Panel>
                <BillingSettings />
              </Tab.Panel>
              <Tab.Panel>
                <SystemLogs />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
}
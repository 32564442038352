import { useState } from 'react';
import { CreditCard, DollarSign, Settings } from 'lucide-react';

export default function BillingSettings() {
  const [stripeSettings, setStripeSettings] = useState({
    publishableKey: '',
    secretKey: '',
    webhookSecret: '',
  });

  const [billingPolicies, setBillingPolicies] = useState({
    gracePeriod: 3,
    autoSuspend: true,
    sendReminders: true,
  });

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Billing Settings</h3>
        <p className="mt-1 text-sm text-gray-500">
          Configure payment gateway and billing policies
        </p>
      </div>

      {/* Stripe Configuration */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center">
          <CreditCard className="h-5 w-5 text-gray-400 mr-2" />
          <h4 className="text-base font-medium text-gray-900">Stripe Configuration</h4>
        </div>
        <div className="mt-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Publishable Key
            </label>
            <input
              type="text"
              value={stripeSettings.publishableKey}
              onChange={(e) => setStripeSettings(prev => ({
                ...prev,
                publishableKey: e.target.value
              }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Secret Key
            </label>
            <input
              type="password"
              value={stripeSettings.secretKey}
              onChange={(e) => setStripeSettings(prev => ({
                ...prev,
                secretKey: e.target.value
              }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Webhook Secret
            </label>
            <input
              type="password"
              value={stripeSettings.webhookSecret}
              onChange={(e) => setStripeSettings(prev => ({
                ...prev,
                webhookSecret: e.target.value
              }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      {/* Billing Policies */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center">
          <Settings className="h-5 w-5 text-gray-400 mr-2" />
          <h4 className="text-base font-medium text-gray-900">Billing Policies</h4>
        </div>
        <div className="mt-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Grace Period (days)
            </label>
            <input
              type="number"
              min="0"
              max="30"
              value={billingPolicies.gracePeriod}
              onChange={(e) => setBillingPolicies(prev => ({
                ...prev,
                gracePeriod: parseInt(e.target.value)
              }))}
              className="mt-1 block w-20 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={billingPolicies.autoSuspend}
              onChange={(e) => setBillingPolicies(prev => ({
                ...prev,
                autoSuspend: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Auto-suspend overdue accounts
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={billingPolicies.sendReminders}
              onChange={(e) => setBillingPolicies(prev => ({
                ...prev,
                sendReminders: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Send payment reminders
            </label>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
import { useState } from 'react';
import { Shield, Key, Lock } from 'lucide-react';

export default function SecuritySettings() {
  const [passwordPolicy, setPasswordPolicy] = useState({
    minLength: 8,
    requireUppercase: true,
    requireNumbers: true,
    requireSpecialChars: true,
  });

  const [mfaEnabled, setMfaEnabled] = useState(true);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Security Settings</h3>
        <p className="mt-1 text-sm text-gray-500">
          Configure system-wide security settings and policies
        </p>
      </div>

      {/* Password Policy */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center">
          <Key className="h-5 w-5 text-gray-400 mr-2" />
          <h4 className="text-base font-medium text-gray-900">Password Policy</h4>
        </div>
        <div className="mt-4 space-y-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={passwordPolicy.requireUppercase}
              onChange={(e) => setPasswordPolicy(prev => ({
                ...prev,
                requireUppercase: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Require uppercase letters
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={passwordPolicy.requireNumbers}
              onChange={(e) => setPasswordPolicy(prev => ({
                ...prev,
                requireNumbers: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Require numbers
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={passwordPolicy.requireSpecialChars}
              onChange={(e) => setPasswordPolicy(prev => ({
                ...prev,
                requireSpecialChars: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Require special characters
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="number"
              min="8"
              max="32"
              value={passwordPolicy.minLength}
              onChange={(e) => setPasswordPolicy(prev => ({
                ...prev,
                minLength: parseInt(e.target.value)
              }))}
              className="mt-1 block w-20 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Minimum password length
            </label>
          </div>
        </div>
      </div>

      {/* MFA Settings */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center">
          <Shield className="h-5 w-5 text-gray-400 mr-2" />
          <h4 className="text-base font-medium text-gray-900">Multi-Factor Authentication</h4>
        </div>
        <div className="mt-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={mfaEnabled}
              onChange={(e) => setMfaEnabled(e.target.checked)}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Require MFA for all admin users
            </label>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
import { User } from 'lucide-react';

interface Player {
  id: string;
  name: string;
  email: string;
  status: 'active' | 'inactive';
}

interface PlayerListProps {
  players: Player[];
  onRemovePlayer: (playerId: string) => void;
}

export default function PlayerList({ players, onRemovePlayer }: PlayerListProps) {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <ul className="divide-y divide-gray-200">
        {players.map((player) => (
          <li key={player.id} className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-primary-100 flex items-center justify-center">
                    <User className="h-5 w-5 text-primary-600" />
                  </div>
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">{player.name}</div>
                  <div className="text-sm text-gray-500">{player.email}</div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                  player.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {player.status}
                </span>
                <button
                  onClick={() => onRemovePlayer(player.id)}
                  className="text-sm text-red-600 hover:text-red-900"
                >
                  Remove
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  arrayUnion,
  arrayRemove,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { Team } from '../../types/user';

export async function getTeams(tenantId: string): Promise<Team[]> {
  const teamsQuery = query(
    collection(db, 'teams'),
    where('tenantId', '==', tenantId)
  );
  
  const snapshot = await getDocs(teamsQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate(),
    updatedAt: doc.data().updatedAt.toDate(),
  } as Team));
}

export async function createTeam(team: Omit<Team, 'id'>): Promise<Team> {
  const now = Timestamp.now();
  const docRef = await addDoc(collection(db, 'teams'), {
    ...team,
    playerIds: [],
    createdAt: now,
    updatedAt: now,
  });

  return {
    id: docRef.id,
    ...team,
    playerIds: [],
    createdAt: now.toDate(),
    updatedAt: now.toDate(),
  };
}

export async function updateTeam(id: string, data: Partial<Team>): Promise<void> {
  const now = Timestamp.now();
  const teamRef = doc(db, 'teams', id);
  await updateDoc(teamRef, {
    ...data,
    updatedAt: now,
  });
}

export async function deleteTeam(id: string): Promise<void> {
  await deleteDoc(doc(db, 'teams', id));
}

export async function getTeam(id: string): Promise<Team | null> {
  const teamDoc = await getDoc(doc(db, 'teams', id));
  if (!teamDoc.exists()) {
    return null;
  }

  const data = teamDoc.data();
  return {
    id: teamDoc.id,
    ...data,
    createdAt: data.createdAt.toDate(),
    updatedAt: data.updatedAt.toDate(),
  } as Team;
}

export async function addPlayerToTeam(teamId: string, playerId: string): Promise<void> {
  const now = Timestamp.now();
  const teamRef = doc(db, 'teams', teamId);
  await updateDoc(teamRef, {
    playerIds: arrayUnion(playerId),
    updatedAt: now,
  });
}

export async function removePlayerFromTeam(teamId: string, playerId: string): Promise<void> {
  const now = Timestamp.now();
  const teamRef = doc(db, 'teams', teamId);
  await updateDoc(teamRef, {
    playerIds: arrayRemove(playerId),
    updatedAt: now,
  });
}

export async function assignCoachToTeam(teamId: string, coachId: string): Promise<void> {
  const now = Timestamp.now();
  const teamRef = doc(db, 'teams', teamId);
  await updateDoc(teamRef, {
    coachId,
    updatedAt: now,
  });
}

export async function allocatePlayerToTeam(params: {
  teamId: string;
  tenantId: string;
  email: string;
  displayName: string;
}): Promise<void> {
  const { teamId, email } = params;
  await addPlayerToTeam(teamId, email);
}
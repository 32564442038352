import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  updateProfile as firebaseUpdateProfile,
  User as FirebaseUser
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, Timestamp, collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import { User } from '../../types/user';

export async function signIn(email: string, password: string): Promise<User> {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const userData = await getUserData(userCredential.user);
  return userData;
}

export async function signUp(email: string, password: string, displayName: string): Promise<User> {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await firebaseUpdateProfile(userCredential.user, { displayName });
  
  const now = Timestamp.now();
  const userData: Omit<User, 'id'> = {
    email,
    displayName,
    role: 'admin',
    tenantId: '',
    isSuperAdmin: false,
    createdAt: now.toDate(),
    updatedAt: now.toDate()
  };

  await setDoc(doc(db, 'users', userCredential.user.uid), {
    ...userData,
    createdAt: now,
    updatedAt: now,
  });

  return {
    id: userCredential.user.uid,
    ...userData
  };
}

export async function signOut(): Promise<void> {
  await firebaseSignOut(auth);
}

export async function resetPassword(email: string): Promise<void> {
  await sendPasswordResetEmail(auth, email);
}

export async function updateProfile(userId: string, data: Partial<User>): Promise<void> {
  const now = Timestamp.now();
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    throw new Error('User not found');
  }

  // Prevent modification of super admin status
  if ('isSuperAdmin' in data) {
    delete data.isSuperAdmin;
  }

  await updateDoc(userRef, {
    ...data,
    updatedAt: now,
  });

  if (auth.currentUser && data.displayName) {
    await firebaseUpdateProfile(auth.currentUser, {
      displayName: data.displayName,
      photoURL: data.photoURL,
    });
  }
}

export async function getUserData(firebaseUser: FirebaseUser): Promise<User> {
  const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
  
  if (!userDoc.exists()) {
    throw new Error('User data not found');
  }
  
  const userData = userDoc.data();
  return {
    id: firebaseUser.uid,
    ...userData,
    createdAt: userData.createdAt.toDate(),
    updatedAt: userData.updatedAt.toDate()
  } as User;
}

export async function getAllUsers(): Promise<User[]> {
  const usersSnapshot = await getDocs(collection(db, 'users'));
  return usersSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate(),
    updatedAt: doc.data().updatedAt.toDate(),
  } as User));
}
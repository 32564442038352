import { useAuth } from '../context/AuthContext';
import PageHeader from '../components/common/PageHeader';

export default function Dashboard() {
  const { user } = useAuth();

  return (
    <div>
      <PageHeader 
        title={`Welcome, ${user?.displayName || 'User'}`}
        description="Here's an overview of your sports academy"
      />
      
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {/* Dashboard content will go here */}
      </div>
    </div>
  );
}
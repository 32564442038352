import { useState } from 'react';
import SubscriptionList from '../../components/superadmin/SubscriptionList';
import SubscriptionStats from '../../components/superadmin/SubscriptionStats';
import SubscriptionFilters from '../../components/superadmin/SubscriptionFilters';

export default function SubscriptionManagement() {
  const [searchQuery, setSearchQuery] = useState('');
  const [planFilter, setPlanFilter] = useState<'all' | 'free' | 'starter' | 'professional' | 'enterprise'>('all');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'canceled' | 'past_due'>('all');

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Subscriptions</h1>
          <p className="mt-1 text-sm text-gray-500">
            Monitor and manage organization subscriptions
          </p>
        </div>
      </div>

      <SubscriptionStats />

      <SubscriptionFilters
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        planFilter={planFilter}
        onPlanChange={setPlanFilter}
        statusFilter={statusFilter}
        onStatusChange={setStatusFilter}
      />

      <SubscriptionList
        searchQuery={searchQuery}
        planFilter={planFilter}
        statusFilter={statusFilter}
      />
    </div>
  );
}
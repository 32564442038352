import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';
import { updateTeam } from '../../lib/team/team-service';

const playerSchema = z.object({
  email: z.string().email('Invalid email address'),
});

type PlayerFormData = z.infer<typeof playerSchema>;

interface AddPlayerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  onPlayerAdded: (playerId: string) => void;
}

export default function AddPlayerDialog({
  isOpen,
  onClose,
  teamId,
  onPlayerAdded,
}: AddPlayerDialogProps) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm<PlayerFormData>({
    resolver: zodResolver(playerSchema),
  });

  const onSubmit = async (data: PlayerFormData) => {
    setLoading(true);
    try {
      // TODO: Implement player invitation and team update
      const playerId = data.email; // Temporary, should be actual user ID
      await updateTeam(teamId, {
        playerIds: [playerId]
      });
      onPlayerAdded(playerId);
      toast.success('Player added successfully!');
      reset();
      onClose();
    } catch (error) {
      toast.error('Failed to add player');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative bg-white rounded-lg w-full max-w-md p-6">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <Dialog.Title className="text-lg font-medium text-gray-900">
            Add Player
          </Dialog.Title>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Player Email
              </label>
              <input
                type="email"
                {...register('email')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                placeholder="Enter player's email"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary-600 border border-transparent rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
              >
                {loading ? 'Adding...' : 'Add Player'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
}
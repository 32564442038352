import { Link } from 'react-router-dom';
import { Mail, Phone, MapPin } from 'lucide-react';

const navigation = {
  product: [
    { name: 'Features', href: '#' },
    { name: 'Pricing', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Contact', href: '#' },
  ],
  legal: [
    { name: 'Privacy Policy', href: '#' },
    { name: 'Terms of Service', href: '#' },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 lg:px-8">
        <div className="border-t border-gray-900/10 pt-12">
          {/* Get in touch section */}
          <div className="mb-12">
            <h3 className="text-2xl font-bold text-center mb-8">Get in touch</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex items-center justify-center gap-2">
                <Mail className="h-5 w-5 text-gray-400" />
                <a href="mailto:support@sportsmanagerhq.com" className="text-gray-600 hover:text-gray-900">
                  support@sportsmanagerhq.com
                </a>
              </div>
              <div className="flex items-center justify-center gap-2">
                <Phone className="h-5 w-5 text-gray-400" />
                <a href="tel:(555) 123-4567" className="text-gray-600 hover:text-gray-900">
                  (555) 123-4567
                </a>
              </div>
              <div className="flex items-center justify-center gap-2">
                <MapPin className="h-5 w-5 text-gray-400" />
                <span className="text-gray-600">
                  123 Sports Ave, Suite 100<br />
                  San Francisco, CA 94105
                </span>
              </div>
            </div>
          </div>

          {/* Navigation */}
          <div className="grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0 md:grid-cols-3">
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Product</h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.product.map((item) => (
                  <li key={item.name}>
                    <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Company</h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
              <ul role="list" className="mt-6 space-y-4">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Bottom section */}
          <div className="mt-12 border-t border-gray-900/10 pt-8">
            <p className="text-xs leading-5 text-gray-500">
              &copy; {new Date().getFullYear()} Sports Manager HQ. All rights reserved.
            </p>
            <p className="text-xs leading-5 text-gray-500 mt-2">
              Making sports management simple.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
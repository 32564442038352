import { NavLink } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  ClipboardCheck, 
  Receipt, 
  BarChart4,
  Settings,
  Building2,
  CreditCard,
  UserCog,
  Shield,
  UserPlus,
  UserCheck
} from 'lucide-react';
import { cn } from '../../lib/utils';
import { useAuth } from '../../context/AuthContext';

interface SidebarProps {
  open: boolean;
  onClose: () => void;
}

export default function Sidebar({ open, onClose }: SidebarProps) {
  const { user } = useAuth();

  const navigation = user?.isSuperAdmin 
    ? [
        { name: 'Dashboard', to: '/dashboard', icon: LayoutDashboard },
        { name: 'Organizations', to: '/organizations', icon: Building2 },
        { name: 'Users', to: '/users', icon: UserCog },
        { name: 'Subscriptions', to: '/subscriptions', icon: CreditCard },
        { name: 'System Settings', to: '/system-settings', icon: Shield },
      ]
    : [
        { name: 'Dashboard', to: '/dashboard', icon: LayoutDashboard },
        { name: 'Teams', to: '/teams', icon: Users },
        { name: 'Coaches', to: '/coaches', icon: UserCheck },
        { name: 'Players', to: '/players', icon: UserPlus },
        { name: 'Attendance', to: '/attendance', icon: ClipboardCheck },
        { name: 'Invoices', to: '/invoices', icon: Receipt },
        { name: 'Analytics', to: '/analytics', icon: BarChart4 },
        { name: 'Settings', to: '/settings', icon: Settings },
      ];

  return (
    <>
      {/* Mobile backdrop */}
      {open && (
        <div 
          className="fixed inset-0 bg-gray-900/50 lg:hidden z-40"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div className={cn(
        "fixed top-0 left-0 bottom-0 w-72 bg-white shadow-lg z-50 transform transition-transform duration-200 lg:transform-none lg:relative",
        open ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
      )}>
        <div className="h-full flex flex-col">
          {/* Navigation */}
          <nav className="flex-1 px-4 space-y-1 overflow-y-auto py-4">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.to}
                className={({ isActive }) =>
                  cn(
                    "group flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors",
                    isActive
                      ? "bg-primary-50 text-primary-700"
                      : "text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  )
                }
                onClick={() => onClose()}
              >
                <item.icon
                  className={cn(
                    "mr-3 h-5 w-5 flex-shrink-0 transition-colors",
                    "text-gray-400 group-hover:text-gray-500"
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}
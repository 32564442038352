import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { Tenant } from '../../types/tenant';

export async function getAllOrganizations(): Promise<Tenant[]> {
  const tenantsQuery = query(
    collection(db, 'tenants'),
    orderBy('createdAt', 'desc')
  );
  
  const snapshot = await getDocs(tenantsQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate(),
    updatedAt: doc.data().updatedAt.toDate(),
  } as Tenant));
}

export async function getOrganizationDetails(id: string): Promise<Tenant | null> {
  const orgDoc = await getDoc(doc(db, 'tenants', id));
  if (!orgDoc.exists()) {
    return null;
  }

  return {
    id: orgDoc.id,
    ...orgDoc.data(),
    createdAt: orgDoc.data().createdAt.toDate(),
    updatedAt: orgDoc.data().updatedAt.toDate(),
  } as Tenant;
}

export async function updateOrganization(
  id: string,
  data: Partial<Tenant>
): Promise<void> {
  const orgRef = doc(db, 'tenants', id);
  await updateDoc(orgRef, {
    ...data,
    updatedAt: new Date(),
  });
}
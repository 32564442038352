import { useState, useEffect } from 'react';
import { AlertTriangle, CheckCircle, Info } from 'lucide-react';
import { formatRelativeTime } from '../../utils/date-utils';

interface SystemLog {
  id: string;
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
  timestamp: Date;
  details?: string;
}

export default function SystemLogs() {
  const [logs, setLogs] = useState<SystemLog[]>([
    {
      id: '1',
      type: 'error',
      message: 'Database backup failed',
      timestamp: new Date(Date.now() - 1000 * 60 * 30),
      details: 'Connection timeout during backup process',
    },
    {
      id: '2',
      type: 'success',
      message: 'System update completed',
      timestamp: new Date(Date.now() - 1000 * 60 * 60),
    },
    {
      id: '3',
      type: 'warning',
      message: 'High CPU usage detected',
      timestamp: new Date(Date.now() - 1000 * 60 * 90),
      details: 'CPU usage exceeded 80% for 5 minutes',
    },
  ]);

  const [filter, setFilter] = useState<'all' | 'error' | 'warning' | 'info' | 'success'>('all');

  const getIcon = (type: SystemLog['type']) => {
    switch (type) {
      case 'error':
        return <AlertTriangle className="h-5 w-5 text-red-500" />;
      case 'warning':
        return <AlertTriangle className="h-5 w-5 text-yellow-500" />;
      case 'success':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      default:
        return <Info className="h-5 w-5 text-blue-500" />;
    }
  };

  const filteredLogs = logs.filter(log => filter === 'all' || log.type === filter);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-medium text-gray-900">System Logs</h3>
          <p className="mt-1 text-sm text-gray-500">
            View and monitor system events and errors
          </p>
        </div>
        <div>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value as any)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
          >
            <option value="all">All Events</option>
            <option value="error">Errors</option>
            <option value="warning">Warnings</option>
            <option value="info">Info</option>
            <option value="success">Success</option>
          </select>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {filteredLogs.map((log) => (
            <li key={log.id}>
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {getIcon(log.type)}
                    <p className="ml-3 text-sm font-medium text-gray-900">
                      {log.message}
                    </p>
                  </div>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                      {formatRelativeTime(log.timestamp)}
                    </p>
                  </div>
                </div>
                {log.details && (
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="text-sm text-gray-500">{log.details}</p>
                    </div>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';
import { assignCoachToTeam } from '../../lib/coach/coach-service';
import { User } from '../../types/user';

const assignCoachSchema = z.object({
  coachId: z.string().min(1, 'Please select a coach'),
});

type AssignCoachFormData = z.infer<typeof assignCoachSchema>;

interface AssignCoachDialogProps {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  coaches: User[];
  currentCoachId?: string;
  onCoachAssigned: (coachId: string) => void;
}

export default function AssignCoachDialog({
  isOpen,
  onClose,
  teamId,
  coaches,
  currentCoachId,
  onCoachAssigned,
}: AssignCoachDialogProps) {
  const { register, handleSubmit, formState: { errors } } = useForm<AssignCoachFormData>({
    resolver: zodResolver(assignCoachSchema),
    defaultValues: {
      coachId: currentCoachId || '',
    },
  });

  const onSubmit = async (data: AssignCoachFormData) => {
    try {
      await assignCoachToTeam(data.coachId, teamId);
      onCoachAssigned(data.coachId);
      toast.success('Coach assigned successfully!');
      onClose();
    } catch (error) {
      console.error('Failed to assign coach:', error);
      toast.error('Failed to assign coach');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative bg-white rounded-lg w-full max-w-md p-6">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <Dialog.Title className="text-lg font-medium text-gray-900">
            {currentCoachId ? 'Change Coach' : 'Assign Coach'}
          </Dialog.Title>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Select Coach
              </label>
              <select
                {...register('coachId')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              >
                <option value="">Select a coach</option>
                {coaches.map((coach) => (
                  <option key={coach.id} value={coach.id}>
                    {coach.displayName}
                  </option>
                ))}
              </select>
              {errors.coachId && (
                <p className="mt-1 text-sm text-red-600">{errors.coachId.message}</p>
              )}
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary-600 border border-transparent rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {currentCoachId ? 'Change Coach' : 'Assign Coach'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
}
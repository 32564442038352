import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Landing from './pages/Landing';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Layout from './components/layout/Layout';
import UserProfile from './pages/UserProfile';
import TeamList from './pages/teams/TeamList';
import TeamDetails from './pages/teams/TeamDetails';
import CoachList from './pages/coaches/CoachList';
import PlayerList from './pages/players/PlayerList';
import CreateTenant from './pages/CreateTenant';
import LoadingSpinner from './components/common/LoadingSpinner';
import SuperAdminDashboard from './pages/superadmin/Dashboard';
import OrganizationList from './pages/superadmin/OrganizationList';
import UserManagement from './pages/superadmin/UserManagement';
import SubscriptionManagement from './pages/superadmin/SubscriptionManagement';
import SystemSettings from './pages/superadmin/SystemSettings';

export default function AppRoutes() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={!user ? <Landing /> : <Navigate to="/dashboard" replace />} />
      <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" replace />} />
      <Route path="/register" element={!user ? <Register /> : <Navigate to="/dashboard" replace />} />

      {/* Protected routes */}
      {user && (
        <Route element={<Layout />}>
          {user.isSuperAdmin ? (
            <>
              <Route path="/dashboard" element={<SuperAdminDashboard />} />
              <Route path="/organizations" element={<OrganizationList />} />
              <Route path="/users" element={<UserManagement />} />
              <Route path="/subscriptions" element={<SubscriptionManagement />} />
              <Route path="/system-settings" element={<SystemSettings />} />
            </>
          ) : (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/teams" element={<TeamList />} />
              <Route path="/teams/:teamId" element={<TeamDetails />} />
              <Route path="/coaches" element={<CoachList />} />
              <Route path="/players" element={<PlayerList />} />
            </>
          )}
          <Route path="/profile" element={<UserProfile />} />
        </Route>
      )}

      {/* Catch all */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
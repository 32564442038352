import { Users, CalendarCheck, Star } from 'lucide-react';

interface TeamStatsCardsProps {
  playerCount: number;
  attendanceRate: number;
  topPerformers: Array<{
    id: string;
    name: string;
    score: number;
  }>;
}

export default function TeamStatsCards({ playerCount, attendanceRate, topPerformers }: TeamStatsCardsProps) {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
      {/* Total Players */}
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Users className="h-6 w-6 text-gray-400" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Total Players</dt>
                <dd className="flex items-baseline">
                  <div className="text-2xl font-semibold text-gray-900">{playerCount}</div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* Attendance Rate */}
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <CalendarCheck className="h-6 w-6 text-gray-400" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Attendance Rate</dt>
                <dd className="flex items-baseline">
                  <div className="text-2xl font-semibold text-gray-900">{attendanceRate}%</div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* Top Performers */}
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Star className="h-6 w-6 text-gray-400" />
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">Top Performers</dt>
                <dd className="mt-2">
                  {topPerformers.map((performer, index) => (
                    <div key={performer.id} className="flex items-center justify-between text-sm">
                      <span className="text-gray-900">{index + 1}. {performer.name}</span>
                      <span className="text-gray-500">{performer.score}%</span>
                    </div>
                  ))}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Users, Trash2, UserPlus, UserCheck } from 'lucide-react';
import toast from 'react-hot-toast';
import { Team, User } from '../../types/user';
import { getTeam, deleteTeam } from '../../lib/team/team-service';
import { getCoachesByTenant } from '../../lib/coach/coach-service';
import { useTenant } from '../../context/TenantContext';
import AddPlayerDialog from '../../components/team/AddPlayerDialog';
import DeleteConfirmDialog from '../../components/common/DeleteConfirmDialog';
import TeamStatsCards from '../../components/team/TeamStatsCards';
import AssignCoachDialog from '../../components/team/AssignCoachDialog';
import PlayerList from '../../components/team/PlayerList';
import LoadingSpinner from '../../components/common/LoadingSpinner';

export default function TeamDetails() {
  const { teamId } = useParams<{ teamId: string }>();
  const navigate = useNavigate();
  const { tenant } = useTenant();
  
  const [team, setTeam] = useState<Team | null>(null);
  const [coaches, setCoaches] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [isAddPlayerDialogOpen, setIsAddPlayerDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAssignCoachDialogOpen, setIsAssignCoachDialogOpen] = useState(false);

  useEffect(() => {
    async function loadData() {
      if (!teamId || !tenant) return;
      
      try {
        const [teamData, coachList] = await Promise.all([
          getTeam(teamId),
          getCoachesByTenant(tenant.id)
        ]);
        setTeam(teamData);
        setCoaches(coachList);
      } catch (error) {
        console.error('Failed to load team:', error);
        toast.error('Failed to load team details');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [teamId, tenant]);

  const handleDeleteTeam = async () => {
    if (!teamId) return;

    try {
      await deleteTeam(teamId);
      toast.success('Team deleted successfully');
      navigate('/teams');
    } catch (error) {
      console.error('Failed to delete team:', error);
      toast.error('Failed to delete team');
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!team) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-semibold text-gray-900">Team not found</h2>
        <p className="mt-2 text-gray-500">The team you're looking for doesn't exist.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="h-12 w-12 rounded-lg bg-primary-100 flex items-center justify-center">
            <Users className="h-8 w-8 text-primary-600" />
          </div>
          <div className="ml-4">
            <h1 className="text-2xl font-bold text-gray-900">{team.name}</h1>
            <p className="text-sm text-gray-500">
              {team.playerIds.length} players · {coaches.find(c => c.id === team.coachId)?.displayName || 'No coach assigned'}
            </p>
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            onClick={() => setIsAddPlayerDialogOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700"
          >
            <UserPlus className="h-5 w-5 mr-2" />
            Add Player
          </button>
          <button
            onClick={() => setIsAssignCoachDialogOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
          >
            <UserCheck className="h-5 w-5 mr-2" />
            {team.coachId ? 'Change Coach' : 'Assign Coach'}
          </button>
          <button
            onClick={() => setIsDeleteDialogOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-red-600 hover:text-red-700"
          >
            <Trash2 className="h-5 w-5 mr-2" />
            Delete Team
          </button>
        </div>
      </div>

      {/* Team Stats */}
      <TeamStatsCards
        playerCount={team.playerIds.length}
        attendanceRate={85} // TODO: Calculate from actual attendance data
        topPerformers={[
          { id: '1', name: 'Player 1', score: 95 },
          { id: '2', name: 'Player 2', score: 90 },
          { id: '3', name: 'Player 3', score: 88 },
        ]}
      />

      {/* Player List */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Team Members
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Manage players and their roles
          </p>
        </div>
        <PlayerList
          players={team.playerIds.map(id => ({
            id,
            name: `Player ${id}`, // TODO: Get actual player names
            email: `player${id}@example.com`,
            status: 'active'
          }))}
          onRemovePlayer={(playerId) => {
            // TODO: Implement player removal
            console.log('Remove player:', playerId);
          }}
        />
      </div>

      {/* Dialogs */}
      <AddPlayerDialog
        isOpen={isAddPlayerDialogOpen}
        onClose={() => setIsAddPlayerDialogOpen(false)}
        teamId={team.id}
        onPlayerAdded={(playerId) => {
          setTeam({
            ...team,
            playerIds: [...team.playerIds, playerId]
          });
        }}
      />

      <AssignCoachDialog
        isOpen={isAssignCoachDialogOpen}
        onClose={() => setIsAssignCoachDialogOpen(false)}
        teamId={team.id}
        coaches={coaches}
        currentCoachId={team.coachId}
        onCoachAssigned={(coachId) => {
          setTeam({
            ...team,
            coachId
          });
        }}
      />

      <DeleteConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteTeam}
        title="Delete Team"
        message="Are you sure you want to delete this team? This action cannot be undone."
      />
    </div>
  );
}
import { Link } from 'react-router-dom';
import { Check } from 'lucide-react';

const plans = [
  {
    name: 'Starter',
    price: 49,
    features: [
      'Up to 50 players',
      '2 coach accounts',
      'Basic attendance tracking',
      'Email support',
      'Core features'
    ]
  },
  {
    name: 'Professional',
    price: 99,
    popular: true,
    features: [
      'Up to 250 players',
      '6 coach accounts',
      'Advanced attendance tracking',
      'Invoice generation',
      'Priority support',
      'Advanced analytics',
      'Custom branding'
    ]
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    features: [
      'Unlimited players',
      'Unlimited coach accounts',
      'Custom features',
      '24/7 dedicated support',
      'Custom integrations',
      'SLA agreement',
      'Onboarding assistance'
    ]
  }
];

export default function PricingSection() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Simple, transparent pricing
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Choose the plan that best fits your academy's needs
          </p>
        </div>
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`rounded-3xl p-8 ring-1 ${
                plan.popular
                  ? 'bg-primary-50/10 ring-primary-200'
                  : 'ring-gray-200'
              }`}
            >
              <h3 className="text-lg font-semibold leading-8 text-gray-900">
                {plan.name}
              </h3>
              <p className="mt-4 flex items-baseline gap-x-2">
                {typeof plan.price === 'number' ? (
                  <>
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      ${plan.price}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      /month
                    </span>
                  </>
                ) : (
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {plan.price}
                  </span>
                )}
              </p>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <Check className="h-6 w-5 flex-none text-primary-600" />
                    {feature}
                  </li>
                ))}
              </ul>
              <Link
                to="/register"
                className={`mt-8 block rounded-lg px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                  plan.popular
                    ? 'bg-primary-600 text-white hover:bg-primary-700 focus-visible:outline-primary-600'
                    : 'bg-gray-50 text-gray-900 hover:bg-gray-100'
                }`}
              >
                Get Started
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import { useState } from 'react';
import { User } from '../../types/user';
import UserList from '../../components/superadmin/UserList';
import UserFilters from '../../components/superadmin/UserFilters';

export default function UserManagement() {
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState<'all' | 'admin' | 'coach' | 'player'>('all');

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">User Management</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage all users across organizations
          </p>
        </div>
      </div>

      <UserFilters
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        roleFilter={roleFilter}
        onRoleChange={setRoleFilter}
      />

      <UserList
        searchQuery={searchQuery}
        roleFilter={roleFilter}
      />
    </div>
  );
}
import { useState, useEffect } from 'react';
import { PlusCircle, User, Mail } from 'lucide-react';
import { User as UserType } from '../../types/user';
import { getCoachesByTenant } from '../../lib/coach/coach-service';
import { useTenant } from '../../context/TenantContext';
import AddCoachDialog from '../../components/team/AddCoachDialog';
import LoadingSpinner from '../../components/common/LoadingSpinner';

export default function CoachList() {
  const { tenant } = useTenant();
  const [coaches, setCoaches] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(true);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState<string>('');

  useEffect(() => {
    async function loadCoaches() {
      if (!tenant) return;
      
      try {
        const coachList = await getCoachesByTenant(tenant.id);
        setCoaches(coachList);
      } catch (error) {
        console.error('Failed to load coaches:', error);
      } finally {
        setLoading(false);
      }
    }

    loadCoaches();
  }, [tenant]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Coaches</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your coaching staff and their team assignments
          </p>
        </div>
        <button
          onClick={() => setIsAddDialogOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700"
        >
          <PlusCircle className="h-5 w-5 mr-2" />
          Add Coach
        </button>
      </div>

      {coaches.length === 0 ? (
        <div className="text-center py-12">
          <User className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No coaches</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by adding a new coach to your organization.
          </p>
          <div className="mt-6">
            <button
              onClick={() => setIsAddDialogOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <PlusCircle className="h-5 w-5 mr-2" />
              Add Coach
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Team Assignment
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {coaches.map((coach) => (
                <tr key={coach.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        {coach.photoURL ? (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={coach.photoURL}
                            alt={coach.displayName}
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-full bg-primary-100 flex items-center justify-center">
                            <User className="h-5 w-5 text-primary-600" />
                          </div>
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {coach.displayName}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center text-sm text-gray-500">
                      <Mail className="h-4 w-4 mr-2" />
                      {coach.email}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="inline-flex rounded-full bg-primary-50 px-2 text-xs font-semibold leading-5 text-primary-700">
                      {coach.teamId || 'Unassigned'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Active
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => setSelectedTeamId(coach.id)}
                      className="text-primary-600 hover:text-primary-900"
                    >
                      Assign Team
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <AddCoachDialog
        isOpen={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        teamId={selectedTeamId}
        onCoachAdded={(coachId) => {
          // Refresh coaches list
          if (tenant) {
            getCoachesByTenant(tenant.id).then(setCoaches);
          }
        }}
      />
    </div>
  );
}
import { Link } from 'react-router-dom';
import { LayoutGrid } from 'lucide-react';

export default function HeroSection() {
  return (
    <div className="relative bg-white">
      {/* Navigation */}
      <nav className="absolute w-full top-0 z-50">
        <div className="mx-auto max-w-7xl px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <LayoutGrid className="h-6 w-6 text-primary-600" />
              <span className="ml-2 text-xl font-semibold">Sports Manager HQ</span>
            </div>
            <div className="flex items-center gap-4">
              <Link to="/login" className="text-sm font-medium text-gray-700 hover:text-gray-900">
                Sign In
              </Link>
              <Link
                to="/register"
                className="rounded-lg bg-primary-600 px-4 py-2 text-sm font-medium text-white hover:bg-primary-700"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Content */}
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:text-7xl">
          Manage Your Sports Academy
          <span className="block text-primary-600">with Ease</span>
        </h1>
        <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
          Streamline operations, track attendance, manage teams, and handle invoicing - all
          in one powerful platform designed for sports academies.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/register"
            className="rounded-lg bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-700"
          >
            Get Started
          </Link>
          <Link
            to="/login"
            className="rounded-lg bg-gray-100 px-6 py-3 text-base font-medium text-gray-900 hover:bg-gray-200"
          >
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}
import { Link } from 'react-router-dom';
import { Menu, Bell } from 'lucide-react';
import { useTenant } from '../../context/TenantContext';
import UserMenu from './UserMenu';

interface NavbarProps {
  onMenuClick: () => void;
}

export default function Navbar({ onMenuClick }: NavbarProps) {
  const { tenant } = useTenant();

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <button
              onClick={onMenuClick}
              className="lg:hidden px-4 text-gray-500 focus:outline-none"
            >
              <Menu className="h-6 w-6" />
            </button>
            <div className="flex-shrink-0 flex items-center">
              <Link 
                to="/" 
                className="text-xl font-bold bg-gradient-to-r from-primary-600 to-primary-500 bg-clip-text text-transparent"
              >
                {tenant?.name || 'Sports Manager HQ'}
              </Link>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <button className="p-2 rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-100 transition-colors">
              <Bell className="h-6 w-6" />
            </button>
            <UserMenu />
          </div>
        </div>
      </div>
    </nav>
  );
}
import { Trophy, Users, BarChart, ClipboardCheck } from 'lucide-react';

const features = [
  {
    icon: Trophy,
    title: 'Team Management',
    description: 'Easily manage teams, players, and coaches in one centralized platform'
  },
  {
    icon: Users,
    title: 'Player Tracking',
    description: 'Monitor player performance, attendance, and development over time'
  },
  {
    icon: BarChart,
    title: 'Analytics',
    description: 'Gain insights with comprehensive analytics and performance metrics'
  },
  {
    icon: ClipboardCheck,
    title: 'Attendance',
    description: 'Track attendance and manage schedules effortlessly'
  }
];

export default function FeaturesSection() {
  return (
    <div className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need to run your academy
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-7xl">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature) => {
              const Icon = feature.icon;
              return (
                <div
                  key={feature.title}
                  className="relative flex flex-col items-center rounded-2xl bg-white p-8 shadow-sm"
                >
                  <div className="mb-4 rounded-lg bg-primary-50 p-3">
                    <Icon className="h-6 w-6 text-primary-600" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                  <p className="mt-2 text-center text-gray-600">{feature.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
import { createContext, useContext, useState, useEffect } from 'react';
import { Tenant } from '../types/tenant';
import { useAuth } from './AuthContext';
import { getTenant } from '../lib/tenant/tenant-service';
import LoadingSpinner from '../components/common/LoadingSpinner';

interface TenantContextType {
  tenant: Tenant | null;
  loading: boolean;
  error: Error | null;
}

const TenantContext = createContext<TenantContextType>({
  tenant: null,
  loading: true,
  error: null,
});

export function TenantProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function loadTenant() {
      if (!user?.tenantId) {
        setLoading(false);
        return;
      }

      try {
        const tenantData = await getTenant(user.tenantId);
        setTenant(tenantData);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to load tenant'));
      } finally {
        setLoading(false);
      }
    }

    loadTenant();
  }, [user?.tenantId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900">Error loading organization</h2>
          <p className="mt-2 text-sm text-gray-500">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <TenantContext.Provider value={{ tenant, loading, error }}>
      {children}
    </TenantContext.Provider>
  );
}

export function useTenant() {
  return useContext(TenantContext);
}
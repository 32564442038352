import { useState } from 'react';
import { Mail, Settings, Bell } from 'lucide-react';

export default function EmailSettings() {
  const [smtpSettings, setSmtpSettings] = useState({
    host: 'smtp.example.com',
    port: 587,
    username: '',
    password: '',
    encryption: 'tls',
  });

  const [emailTemplates, setEmailTemplates] = useState({
    welcomeEnabled: true,
    invoiceEnabled: true,
    reminderEnabled: true,
  });

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Email Settings</h3>
        <p className="mt-1 text-sm text-gray-500">
          Configure system email settings and notification templates
        </p>
      </div>

      {/* SMTP Settings */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center">
          <Settings className="h-5 w-5 text-gray-400 mr-2" />
          <h4 className="text-base font-medium text-gray-900">SMTP Configuration</h4>
        </div>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">SMTP Host</label>
            <input
              type="text"
              value={smtpSettings.host}
              onChange={(e) => setSmtpSettings(prev => ({ ...prev, host: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">SMTP Port</label>
            <input
              type="number"
              value={smtpSettings.port}
              onChange={(e) => setSmtpSettings(prev => ({ ...prev, port: parseInt(e.target.value) }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              value={smtpSettings.username}
              onChange={(e) => setSmtpSettings(prev => ({ ...prev, username: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={smtpSettings.password}
              onChange={(e) => setSmtpSettings(prev => ({ ...prev, password: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      {/* Email Templates */}
      <div className="bg-white shadow-sm rounded-lg p-6">
        <div className="flex items-center">
          <Mail className="h-5 w-5 text-gray-400 mr-2" />
          <h4 className="text-base font-medium text-gray-900">Email Templates</h4>
        </div>
        <div className="mt-4 space-y-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={emailTemplates.welcomeEnabled}
              onChange={(e) => setEmailTemplates(prev => ({
                ...prev,
                welcomeEnabled: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Welcome Email
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={emailTemplates.invoiceEnabled}
              onChange={(e) => setEmailTemplates(prev => ({
                ...prev,
                invoiceEnabled: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Invoice Notifications
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={emailTemplates.reminderEnabled}
              onChange={(e) => setEmailTemplates(prev => ({
                ...prev,
                reminderEnabled: e.target.checked
              }))}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Payment Reminders
            </label>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
import { Star } from 'lucide-react';

const testimonials = [
  {
    quote: "Sports Manager HQ has transformed how we run our academy. The attendance tracking and invoicing features save us hours every week.",
    author: "Sarah Johnson",
    role: "Academy Director",
    rating: 5
  },
  {
    quote: "The team management features are incredible. We can easily track player progress and communicate with parents.",
    author: "Michael Chen",
    role: "Head Coach",
    rating: 5
  },
  {
    quote: "Since implementing Sports Manager HQ, our administrative workload has been cut in half.",
    author: "David Smith",
    role: "Operations Manager",
    rating: 5
  }
];

export default function TestimonialsSection() {
  return (
    <div className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Trusted by leading academies
          </h2>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 md:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col justify-between rounded-2xl bg-white p-8 shadow-sm"
            >
              <div>
                <div className="flex gap-1">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 fill-current text-yellow-400" />
                  ))}
                </div>
                <p className="mt-4 text-lg leading-6 text-gray-600">
                  "{testimonial.quote}"
                </p>
              </div>
              <div className="mt-6">
                <p className="font-semibold text-gray-900">{testimonial.author}</p>
                <p className="text-sm text-gray-600">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import { useState } from 'react';
import OrganizationFilters from '../../components/superadmin/OrganizationFilters';
import OrganizationTable from '../../components/superadmin/OrganizationTable';

export default function OrganizationList() {
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [planFilter, setPlanFilter] = useState<'all' | 'free' | 'starter' | 'professional' | 'enterprise'>('all');

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Organizations</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage and monitor all registered organizations
          </p>
        </div>
      </div>

      <OrganizationFilters
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        statusFilter={statusFilter}
        onStatusChange={setStatusFilter}
        planFilter={planFilter}
        onPlanChange={setPlanFilter}
      />

      <OrganizationTable
        searchQuery={searchQuery}
        statusFilter={statusFilter}
        planFilter={planFilter}
      />
    </div>
  );
}
import { 
  collection,
  doc,
  setDoc,
  getDoc,
  query,
  where,
  getDocs,
  Timestamp
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { Tenant, TenantSettings, SubscriptionPlan } from '../../types/tenant';
import { User } from '../../types/user';

export async function createTenant(
  name: string,
  owner: User,
  plan: SubscriptionPlan = 'free'
): Promise<Tenant> {
  const slug = generateSlug(name);
  await validateSlug(slug);

  const now = Timestamp.now();
  const tenant: Omit<Tenant, 'id'> = {
    name,
    slug,
    plan,
    status: 'active',
    userCount: 1,
    branding: {
      primaryColor: '#0ea5e9',
      secondaryColor: '#0369a1'
    },
    settings: getDefaultSettings(plan),
    createdAt: now.toDate(),
    updatedAt: now.toDate()
  };

  const tenantRef = doc(collection(db, 'tenants'));
  await setDoc(tenantRef, {
    ...tenant,
    createdAt: now,
    updatedAt: now,
  });

  // Update user with tenant ID
  await setDoc(doc(db, 'users', owner.id), {
    ...owner,
    tenantId: tenantRef.id,
    role: 'admin',
    updatedAt: now,
  });

  return {
    id: tenantRef.id,
    ...tenant
  };
}

export async function getTenant(id: string): Promise<Tenant | null> {
  const tenantDoc = await getDoc(doc(db, 'tenants', id));
  if (!tenantDoc.exists()) {
    return null;
  }
  
  return {
    id: tenantDoc.id,
    ...tenantDoc.data(),
    createdAt: tenantDoc.data().createdAt.toDate(),
    updatedAt: tenantDoc.data().updatedAt.toDate(),
  } as Tenant;
}

function getDefaultSettings(plan: SubscriptionPlan): TenantSettings {
  const settings: Record<SubscriptionPlan, TenantSettings> = {
    free: {
      maxTeams: 1,
      maxUsersPerTeam: 10,
      features: {
        attendance: true,
        invoicing: false,
        analytics: false,
        api: false,
        customBranding: false,
        multipleCoaches: false
      },
      timezone: 'UTC',
      locale: 'en-US'
    },
    starter: {
      maxTeams: 3,
      maxUsersPerTeam: 25,
      features: {
        attendance: true,
        invoicing: true,
        analytics: true,
        api: false,
        customBranding: false,
        multipleCoaches: true
      },
      timezone: 'UTC',
      locale: 'en-US'
    },
    professional: {
      maxTeams: 10,
      maxUsersPerTeam: 50,
      features: {
        attendance: true,
        invoicing: true,
        analytics: true,
        api: true,
        customBranding: true,
        multipleCoaches: true
      },
      timezone: 'UTC',
      locale: 'en-US'
    },
    enterprise: {
      maxTeams: -1, // unlimited
      maxUsersPerTeam: -1, // unlimited
      features: {
        attendance: true,
        invoicing: true,
        analytics: true,
        api: true,
        customBranding: true,
        multipleCoaches: true
      },
      timezone: 'UTC',
      locale: 'en-US'
    }
  };

  return settings[plan];
}

async function validateSlug(slug: string): Promise<void> {
  const existingTenant = await getDocs(
    query(collection(db, 'tenants'), where('slug', '==', slug))
  );
  
  if (!existingTenant.empty) {
    throw new Error('Organization name already taken');
  }
}

function generateSlug(name: string): string {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
}